$primary: #0088ff;
$black: #393e47;
$grey: #f4f5f8;
$white: #fff;

:root {
  --primary-color: #144761;
  --secondary-color: #507b92;
  --tertiary-color: #e1ffa7;
  --white-color: white;
  --background-color: #f1f1f1;
  --opacity-lighter: rgba(255, 255, 255, 0.5);
}
