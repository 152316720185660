.rewardBox {
  background-color: var(--primary-color);
  > div {
    color: var(--white-color);
    padding: 30px;
    font-size: 1.1rem;
    height: 100%;
  }
  h1 {
    color: var(--white-color);
  }
}
.text {
  font-size: 1rem;
}
.btn {
  line-height: 1;
  margin-top: 10%;
  background-color: var(--secondary-color);
  border-radius: 100px;
  height: 44px;
  border: none;
  color: #fff;
  padding: 2% 20%;
  font-size: 1.2rem;
  width: 100%;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}
