.padding {
  padding: 0.7rem 0.5rem 0.7rem 0;
}

.barStep {
  position: absolute;
  height: 300%;
  top: -1.5rem;
  z-index: 9;
}


.labelTop {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.labelBottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: var(--secondary-color);
}

.labelLine {
  position: absolute;
  left: 50%;
  background: #000;
  top: 30%;
  height: 40%;
  width: 1px;
}
.avgLine {
  position: absolute;
  background: var(--secondary-color);
  top: 30%;
  height: 40%;
  width: 5px;
  transform: translateX(-2px);
  z-index: 100;
}

.progress {
  position: relative;
  height: 1.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progressBar {
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: var(--primary-color);
}
.text {
  position: absolute;
  z-index: 100;
  left: 0;
  text-align: center;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #fff;
}

.axisTitle {
  font-size: 0.6rem;
  color: grey;
  justify-content: center;
}
