.totalBox {
  font-size: 2rem;
  text-align: right;
  
}
.title {
  color: var(--secondary-color);
}
.points {
  color: var(--primary-color);
}
