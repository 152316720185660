.scoreBox {
  background-color: var(--primary-color);
  > div {
    color: var(--white-color);
    padding: 30px;
    font-size: 1.1rem;
    .aquiredScore {
      color: var(--tertiary-color);
      font-size: 3rem;
    }
    .maxScore {
      font-size: 1.4rem;
    }
    .footer {
      font-size: 0.9rem;
      opacity: 0.7;
    }
    .description {
      font-size: 1.4rem;
    }
    .year {
      color: var(--tertiary-color);
    }
  }
}
