.navBar {
  color: var(--primary-color);
  align-items: center;
  background-color: var(--background-color);
}
.padding30 {
  padding: 30px 0;
}
.padding15 {
  padding: 15px 0;
}
.logoStart {
  font-size: 1.3rem;
  font-weight: bold;
}

.logoEnd {
  font-size: 1.3rem;
}

.report,
.goals {
  font-size: 1.1rem;
  color: var(--secondary-color);
}
.selected {
  color: var(--primary-color);
}
.logOutBtn {
  background-color: var(--secondary-color);
  border-radius: 100px;
  height: 44px;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 0 30px;
  cursor: pointer;
  display: inline-block;
}
