.rewardBox {
  background-color: var(--white-color);
  > div {
    color: black;
    padding: 30px;
    font-size: 1.1rem;
  }
}
.title {
  font-size: 2rem;
  color: var(--secondary-color);
  font-weight: normal;
}
.secondary {
  color: var(--secondary-color);
}
.right{
  text-align: right;
}
.total {
  font-size: 1.2rem;
  margin-top: 1.5rem;
}
.bonus {
  color: var(--secondary-color);
  text-align: right;
  float: right;
}
.small {
  font-size: 0.8rem;
}
