.list {
  margin-bottom: 3%;
  h1,
  h2 {
    color: var(--primary-color);
  }
}

.slider {
  .ant-slider-track {
    background-color: green;
  }
  color: green !important;
  cursor: default;
}
.table {
  display: flex;
  color: var(--secondary-color);
}
.alignright {
  text-align: right;
}

.ScoreComparingList {
  &Item {
    &:nth-of-type(odd) {
      background-color: var(--opacity-lighter);
    }
    min-height: 50px;
    align-items: center;
  }
}
.targetValue {
  color: var(--secondary-color);
}
.steps{
  margin: 2% 0;
}