@import url(https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap);
:root{--primary-color: #144761;--secondary-color: #507b92;--tertiary-color: #e1ffa7;--white-color: white;--background-color: #f1f1f1;--opacity-lighter: rgba(255, 255, 255, 0.5)}h1,h2,h3,h4{transition:all .2s cubic-bezier(0.26, 0.08, 0.55, 1);font-weight:400}h1{font-size:2rem;color:#507b92;color:var(--secondary-color);font-weight:normal}h2{font-size:1.2rem}.ant-btn-round{font-size:1rem}p:empty{display:none}#root{height:100%}
body{background-color:var(--background-color) !important}::selection{background:#3390ff !important;color:#fff !important}
.NavBar_navBar__35C-B{color:var(--primary-color);-webkit-align-items:center;align-items:center;background-color:var(--background-color)}.NavBar_padding30__2mHOK{padding:30px 0}.NavBar_padding15__1UQLC{padding:15px 0}.NavBar_logoStart__3ajnc{font-size:1.3rem;font-weight:bold}.NavBar_logoEnd__Eibog{font-size:1.3rem}.NavBar_report__vPyu1,.NavBar_goals__uld9y{font-size:1.1rem;color:var(--secondary-color)}.NavBar_selected__26te4{color:var(--primary-color)}.NavBar_logOutBtn__B9UMp{background-color:var(--secondary-color);border-radius:100px;height:44px;border:none;color:#fff;font-size:1.2rem;padding:0 30px;cursor:pointer;display:inline-block}
.ScoreDescriptionList_list__Zhook{margin-bottom:3%}.ScoreDescriptionList_list__Zhook h1,.ScoreDescriptionList_list__Zhook h2{color:var(--primary-color)}.ScoreDescriptionList_table__2kSC4{display:-webkit-flex;display:flex;color:var(--secondary-color)}.ScoreDescriptionList_alignleft__1OQRI{text-align:left}.ScoreDescriptionList_alignright__gp-NB{text-align:right}.ScoreDescriptionList_targetValue__2vbO4{color:var(--secondary-color)}.ScoreDescriptionList_ScoreDescriptionListItem__1zPGA{min-height:50px;-webkit-align-items:center;align-items:center}.ScoreDescriptionList_ScoreDescriptionListItem__1zPGA:nth-of-type(odd){background-color:var(--opacity-lighter)}
.RewardTable_center__1vuVa{text-align:center}.RewardTable_table__3-qEb:nth-of-type(even){background-color:var(--background-color)}
.Reward_rewardBox__1WQ-X{background-color:var(--white-color)}.Reward_rewardBox__1WQ-X>div{color:#000;padding:30px;font-size:1.1rem}.Reward_title__TxEjK{font-size:2rem;color:var(--secondary-color);font-weight:normal}.Reward_secondary__2VGXP{color:var(--secondary-color)}.Reward_right__qwjJZ{text-align:right}.Reward_total__15tcg{font-size:1.2rem;margin-top:1.5rem}.Reward_bonus__39AYG{color:var(--secondary-color);text-align:right;float:right}.Reward_small__2t0wf{font-size:.8rem}
.PageDescription_descriptionBox__qjsA0{background-color:var(--white-color)}.PageDescription_descriptionBox__qjsA0>div{color:#000;padding:30px;font-size:1.1rem}.PageDescription_title__2x15V{font-size:2rem;color:var(--secondary-color);font-weight:normal}
.NextGoal_rewardBox__3Ip1i{background-color:var(--primary-color)}.NextGoal_rewardBox__3Ip1i>div{color:var(--white-color);padding:30px;font-size:1.1rem;height:100%}.NextGoal_rewardBox__3Ip1i h1{color:var(--white-color)}.NextGoal_text__OpdiB{font-size:1rem}.NextGoal_btn__1BFeg{line-height:1;margin-top:10%;background-color:var(--secondary-color);border-radius:100px;height:44px;border:none;color:#fff;padding:2% 20%;font-size:1.2rem;width:100%;cursor:pointer;text-align:center;-webkit-justify-content:center;justify-content:center}
.FooterBanner_margin__WyAOD{margin-top:2%}
.Score_scoreBox__2wfID{background-color:var(--primary-color)}.Score_scoreBox__2wfID>div{color:var(--white-color);padding:30px;font-size:1.1rem}.Score_scoreBox__2wfID>div .Score_aquiredScore__1AGj_{color:var(--tertiary-color);font-size:3rem}.Score_scoreBox__2wfID>div .Score_maxScore__2UkIA{font-size:1.4rem}.Score_scoreBox__2wfID>div .Score_footer__13ksZ{font-size:.9rem;opacity:.7}.Score_scoreBox__2wfID>div .Score_description__2Pj7a{font-size:1.4rem}.Score_scoreBox__2wfID>div .Score_year__26KLA{color:var(--tertiary-color)}
.Total_totalBox__1lYwU{font-size:2rem;text-align:right}.Total_title__1rAfb{color:var(--secondary-color)}.Total_points__1BsOo{color:var(--primary-color)}
.ContainerWrapper_container__1ydic{max-width:1200px;padding:0 10px;width:100%;margin:0 auto}
.ReportGenerator_btn__1vSkS{background-color:var(--secondary-color);border-radius:100px;height:44px;float:right;border:none;color:#fff;font-size:1.2rem;padding:0 30px;cursor:pointer}.ReportGenerator_ReportGeneratorBox__3nMYR{margin-bottom:10px;height:70px;margin:10px -10px;padding:10px}
.ScoreComparingList_list__1Vv72{margin-bottom:3%}.ScoreComparingList_list__1Vv72 h1,.ScoreComparingList_list__1Vv72 h2{color:var(--primary-color)}.ScoreComparingList_slider__bBOvJ{color:green !important;cursor:default}.ScoreComparingList_slider__bBOvJ .ScoreComparingList_ant-slider-track__14im_{background-color:green}.ScoreComparingList_table__263go{display:-webkit-flex;display:flex;color:var(--secondary-color)}.ScoreComparingList_alignright__1vfMs{text-align:right}.ScoreComparingList_ScoreComparingListItem__2Qw7h{min-height:50px;-webkit-align-items:center;align-items:center}.ScoreComparingList_ScoreComparingListItem__2Qw7h:nth-of-type(odd){background-color:var(--opacity-lighter)}.ScoreComparingList_targetValue__2rm_U{color:var(--secondary-color)}.ScoreComparingList_steps__1B8c1{margin:2% 0}
.KPIGauge_padding__1Yk_T{padding:.7rem .5rem .7rem 0}.KPIGauge_barStep__3XghS{position:absolute;height:300%;top:-1.5rem;z-index:9}.KPIGauge_labelTop__2Ig0D{top:0;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);position:absolute}.KPIGauge_labelBottom__2VYdc{position:absolute;bottom:0;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);color:var(--secondary-color)}.KPIGauge_labelLine__1goMl{position:absolute;left:50%;background:#000;top:30%;height:40%;width:1px}.KPIGauge_avgLine__2mJ_k{position:absolute;background:var(--secondary-color);top:30%;height:40%;width:5px;-webkit-transform:translateX(-2px);transform:translateX(-2px);z-index:100}.KPIGauge_progress__3jTDy{position:relative;height:1.5rem;background-color:#f5f5f5;border-radius:4px;box-shadow:inset 0 1px 2px rgba(0,0,0,.1)}.KPIGauge_progressBar__KpSVm{height:100%;color:#fff;text-align:center;background-color:var(--primary-color)}.KPIGauge_text__yhfNz{position:absolute;z-index:100;left:0;text-align:center;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);color:#fff}.KPIGauge_axisTitle__2g31f{font-size:.6rem;color:gray;-webkit-justify-content:center;justify-content:center}
