.descriptionBox {
  background-color: var(--white-color);
  > div {
    color: black;
    padding: 30px;
    font-size: 1.1rem;
  }
}
.title {
  font-size: 2rem;
  color: var(--secondary-color);
  font-weight: normal;
}
