.btn {
  background-color: var(--secondary-color);
  border-radius: 100px;
  height: 44px;
  float: right;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 0 30px;
  cursor: pointer;
}
.ReportGeneratorBox {
  margin-bottom: 10px;
  height: 70px;
  margin: 10px -10px;
  padding: 10px;
}
