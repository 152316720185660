.list {
  margin-bottom: 3%;
  h1,
  h2 {
    color: var(--primary-color);
  }
}

.table {
  display: flex;
  color: var(--secondary-color);
}
.alignleft {
  text-align: left;
}
.alignright {
  text-align: right;
}
.targetValue {
  color: var(--secondary-color);
}
.ScoreDescriptionList {
  &Item {
    &:nth-of-type(odd) {
      background-color: var(--opacity-lighter);
    }
    min-height: 50px;
    align-items: center;
  }
}
